import React from 'react';
import { string } from 'prop-types';
import { Box, AspectRatio, Text, Button, Center, Link, Image } from '@chakra-ui/react';

import {
  THE_BRIDE,
  YOUTUBE_EMBED,
  WEDDING_AKAD_FULLDATE,
} from '@/constants';
import { BUTTON_PROPS } from '@/constants/colors';
import { ENABLE_MUSLIM_LANGUAGE } from '@/constants/feature-flags';

import WithAnimation from '@/components/Common/WithAnimation';
import txt from './locales';

/**
 * @function YoutubeLiveSection
 * Render Live Streaming component
 * @returns {JSX.Element}
 */
function YoutubeLiveSection({ lang, ...rest }) {
  return (
    <Box position="relative" textAlign="center" bgColor="bgPrimary" {...rest}>
      {/* Ayat Section */}
      <Box padding="32px 32px 100px 32px" color="mainColorText">
        {false && (
          <WithAnimation>
            {ENABLE_MUSLIM_LANGUAGE ? (
              <Text fontSize="sm">{`“${txt.ayatMuslim[lang]}”`}</Text>
            ) : (
              <Text fontSize="sm">{`“${txt.ayat[lang]}”`}</Text>
            )}
          </WithAnimation>
        )}
        {false && (
        <WithAnimation>
          {ENABLE_MUSLIM_LANGUAGE ? (
            <Text fontWeight="bold" marginTop="16px">
              {txt.ayatNameMuslim[lang]}
            </Text>
          ) : (
            <Text fontWeight="bold" marginTop="16px">
              {txt.ayatName[lang]}
            </Text>
          )}
        </WithAnimation>
        )}
      </Box>
      {/* Divider */}
      <Box height="24px" bgColor="bgSecondary" width="100%" />
      {/* LIVE STREAMING SECTION */}
      <Box width="100%" padding="0 32px 32px 32px">
        {/* Live stream section */}
        {false && (
          <WithAnimation>
            <AspectRatio marginTop="-100px" maxW="560px" ratio={16 / 9} boxShadow="xl">
              <iframe
                title={`Live wedding of ${THE_BRIDE}`}
                src={YOUTUBE_EMBED}
                allowFullScreen
                loading="lazy"
              />
            </AspectRatio>
          </WithAnimation>
        )}
        <WithAnimation>
          <Center marginTop="-100px">
            <Image
              src="https://ik.imagekit.io/zaanfa2/tedy-novi/thumbnail.png?updatedAt=1705999701231"
              width="100%"
              boxShadow="xl"
            />
          </Center>
        </WithAnimation>
        <WithAnimation>
          <Text
            fontSize="sm"
            textAlign="center"
            marginTop="12px"
            color="mainColorText"
            dangerouslySetInnerHTML={{
              __html: `${txt.title[lang]} <br />${WEDDING_AKAD_FULLDATE}`,
            }}
          />
        </WithAnimation>
        <WithAnimation>
          <Text
            textAlign="center"
            fontSize="sm"
            color="mainColorText"
            margin="16px 0"
            padding="0 12px 0 12px"
          >
            <span dangerouslySetInnerHTML={{ __html: txt.problem[lang] }} />
          </Text>
        </WithAnimation>
        <Center>
          <Link
            href="https://us06web.zoom.us/j/88236585695?pwd=B9DtM3okVlgTHAPRkRADGIa30JCasz.1"
            target="_blank"
            _hover={{ textDecoration: 'none' }}
          >
            <Button
              size="xs"
              fontWeight="normal"
              variant="solid"
              bgColor="bgAlternative"
              color="white"
              marginBottom="16px"
              letterSpacing="2px"
              fontFamily="body"
              textTransform="uppercase"
              {...BUTTON_PROPS}
            >
              {txt.open[lang]}
            </Button>
          </Link>
        </Center>
      </Box>
    </Box>
  );
}

YoutubeLiveSection.propTypes = {
  lang: string.isRequired,
};

export default React.memo(YoutubeLiveSection);
